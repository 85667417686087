.footer {
  background-color: #272727;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  .lets-connect {
    border-bottom: 1px solid #475467;
    h2 {
      color: #ffffff;
      font-weight: 600;
      font-size: 48px;
    }
    .btn {
      &:hover {
        i {
          transform: rotate(0deg);
        }
      }
      i {
        transition-duration: 0.3s;
        transform: rotate(-45deg);
      }
    }
  }
  .footer-bottom {
    .logo {
      transition-property: transform;
      transition-duration: 0.3s;
      &:hover {
        .text {
          &.show {
            opacity: 1;
            width: auto;
          }
        }
      }
      img {
        display: inline-flex;
        margin-right: 5px;
        transition-property: transform;
        transition-duration: 0.3s;
      }
      .text {
        transition-property: transform;
        transition-duration: 0.3s;
        display: inline-flex;
        color: #ffffff;
        font-size: 24px;
        line-height: 32px;
        font-style: italic;
        padding-left: 3px;
        padding-right: 3px;
        font-family: "Smooch", cursive;
        &.show {
          opacity: 0;
          width: 0;
        }
      }
    }
    .about-text {
      width: 90%;
      color: #ffffff;
      font-weight: 300;
    }
    .social-icons {
      a {
        margin-right: 10px;
        color: #ffffff;
        font-size: 30px;
        &:hover {
          color: #fd853a;
        }
      }
    }
    .footer-link {
      h4 {
        display: block;
        color: #fd853a;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .links {
        a {
          font-size: 14px;
          line-height: 18px;
          color: #ffffff;
          font-weight: 300;
          display: block;
          padding: 7px 0;
          &:hover {
            color: #fd853a;
          }
        }
      }
    }
    .information {
      h4 {
        display: block;
        color: #fd853a;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .form {
        .form-control {
          padding-top: 9px;
          padding-bottom: 9px;
          border-radius: 0px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        .btn {
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 0px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .footer {
    .lets-connect {
      h2 {
        font-size: 36px;
      }
    }
    .footer-bottom {
      .footer-link {
        h4 {
          margin-top: 20px;
        }
      }
      .information {
        h4 {
          margin-top: 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .footer {
    .lets-connect {
      flex-wrap: wrap;
      h2 {
        font-size: 24px;
        margin-bottom: 15px;
        width: 100%;
      }
    }
  }
}
