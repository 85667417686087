.main-heading {
  font-size: 48px;
  font-weight: 600;
  color: rgba(23, 23, 23, 1);
  span {
    color: rgba(253, 133, 58, 1);
  }
}

.service-contents {
  h3 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    color: rgba(23, 23, 23, 1);
    span {
      color: rgba(253, 133, 58, 1);
    }
  }
  .content {
    h4 {
      font-size: 32px;
      line-height: 40px;
      font-weight: 500;
      color: rgba(23, 23, 23, 1);
      border-bottom: 1px solid rgba(71, 84, 103, 1);
    }
    p {
      font-weight: 400;
      color: rgba(52, 64, 84, 1);
    }
    b{
      font-weight: 800;
      color: rgba(52, 64, 84, 1);
    }
    ul, ol {
      margin-left: 0;
      padding-left: 20px;
    }
    li {
      margin-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .main-heading {
    font-size: 32px;
  }
  .service-contents {
    h3 {
      font-size: 24px;
    }
    .content {
      h4 {
        font-size: 24px;
      }
      p {
        font-size: 14px;
      }
      li {
        font-size: 14px;
      }
    }
  }
}
