.header-main {
  padding-top: 20px;
  padding-bottom: 20px;
  .header {
    display: block;
    border-radius: 30px;
    background-color: #171717;
    .logo {
      transition-property: transform;
      transition-duration: 0.3s;
      transform: perspective(1px) translateZ(0);
      &:hover {
        transform: translateX(-8px);
        .text {
          &.show {
            opacity: 1;
            width: auto;
          }
        }
      }
      img {
        display: inline-flex;
        margin-right: 5px;
        transition-property: transform;
        transition-duration: 0.3s;
      }
      .text {
        transition-property: transform;
        transition-duration: 0.3s;
        display: inline-flex;
        color: #ffffff;
        font-size: 24px;
        line-height: 32px;
        font-style: italic;
        padding-left: 3px;
        padding-right: 3px;
        font-family: "Smooch", cursive;
        &.show {
          opacity: 0;
          width: 0;
        }
      }
    }
    .navbar-expand {
      .navbar-nav {
        .nav-link {
          position: relative;
          font-size: 20px;
          line-height: 24px;
          font-weight: 400;
          color: #ffffff;
          padding: 10px 25px;
          border-radius: 30px;
          margin-left: 5px;
          margin-right: 5px;
          &:hover {
            background-color: #363636;
          }
          &.active {
            color: #ffffff;
            font-weight: 600;
            background-color: #fd853a;
          }
        }
      }
    }
  }
  .small-screen-header {
    display: none;
  }
}

@media only screen and (max-width: 1150px) {
  .header-main {
    .header {
      .navbar-expand {
        .navbar-nav {
          .nav-link {
            font-size: 16px;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .header-main {
    .header {
      display: none;
    }
    .small-screen-header {
      display: block;
      position: relative;
      border-radius: 30px;
      background-color: #171717;
      .logo {
        position: relative;
        z-index: 11;
      }
      .navbar {
        position: static;
      }
      .navbar-toggler {
        color: #ffffff;
        position: relative;
        z-index: 11;
        border-color: #ffffff;
        .navbar-toggler-icon {
          background-image: url("../../img/navbar-toggler-icon.png");
        }
      }
      .navbar-collapse {
        position: absolute;
        top: 45px;
        left: 0;
        width: 100%;
        z-index: 10;
        padding: 30px;
        padding-top: 45px;
        background-color: #171717;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;

        .nav-link {
          color: #ffffff;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
}
